import { Link } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux"; 
import {IMGBUCKETURL, IMAGE_BUCKET_URL} from '../../DataStore/ApiConstant';

const LogoComponentAfter = ({login , siteImages}) => {
    
    let url = '/';

    login ?  
    url="/lobby" : url = "/";

    return ( 
        // <div className="item flex-shrink-2 text-center text-lg-start text-md-start flex-lg-grow-1 flex-sm-grow-1">
            <Link to={url}>
            {/* <img src={siteImages.images&&`${IMAGE_BUCKET_URL}${siteImages.images.logo}`} className="img-fluid"/> */}
                {/* <img src={IMAGE_BUCKET_URL + "breeze-new-logo.png"} className="img-fluid "/> */}
                {
                          Object.keys(siteImages.images).length > 0 &&
                      <img
                        src={IMGBUCKETURL + siteImages?.images?.logo_mobile[0]}
                        className="img-fluid d-block d-sm-none"
                        alt=""
                      ></img>
                    }
                {/* <img src={IMGBUCKETURL + "logo_replace.png"} className="img-fluid d-block d-sm-none"/> */}
            </Link>
        // </div>
     );
}

const BindStateToProps = (state=>{
    return {
        siteImages : state.siteImages
    }
})
 
export default connect(BindStateToProps)(LogoComponentAfter);