export const getFormData = function (object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};

export const genarateQueryString = function (params) {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const calculateBonus = (deposit, maxBonues, bonusPresent) => {
  let calBonus = 0;
  calBonus = (parseFloat(deposit) / 100) * parseFloat(bonusPresent);
  if (calBonus > maxBonues && maxBonues != "-1") {
    calBonus = parseFloat(maxBonues);
  }
  if (calBonus > 0) {
    let nf = new Intl.NumberFormat("en-US");
    let bonusFormatted = nf.format(calBonus);
    return bonusFormatted;
  } else {
    return "0";
  }
};

export const calculateTurnover = (
  deposit,
  maxBonues,
  turnOverPresent,
  bonusPresent
) => {
  let turnoverBonus = 0;
  let calTurnover = 0;

  turnoverBonus = (parseFloat(deposit) / 100) * parseFloat(bonusPresent);
  if (turnoverBonus > maxBonues && maxBonues != "-1") {
    calTurnover = parseFloat(maxBonues) * 2 * parseFloat(turnOverPresent);
  } else {
    calTurnover =
      (parseFloat(deposit) + turnoverBonus) * parseFloat(turnOverPresent);
  }
  if (calTurnover > 0) {
    let nf = new Intl.NumberFormat("en-US");
    let turnoverFormatted = nf.format(calTurnover);
    return turnoverFormatted;
  } else {
    return "0";
  }
};

export const formatString = function (string, separator) {
  let updatedString = string.replace(/_/g, " ");
  updatedString =
    updatedString.charAt(0).toUpperCase() + updatedString.slice(1);
  // updatedString = updatedString = string.replace("_", " ");
  // let updatedString = string.replace(separator, " ")

  return updatedString;
};

// export const loadJson = async function () {
//   const response = await fetch('/api/siteContent/bellagioaces.com/site-images/guestgames.json');
//   const jsonData = await response.json();
//   return jsonData;
// };

export const loadJson = async function () {
  const response = await fetch(`/api/siteContent/bellagioaces.com/site-images/guestgames.json?ver=${new Date().getTime()}`);
  const jsonData = await response.json();
  return jsonData;
};