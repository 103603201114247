import { Fragment } from "react";
// import { GUEST_GAMES_DATA } from "../../DataStore/ApiConstant";
import { Link } from "react-router-dom/cjs/react-router-dom";

import { useEffect,useState } from "react";
import { loadJson } from "../../utils";
import { IMAGE_BUCKET_URL, IMGBUCKETURL } from "../../DataStore/ApiConstant";
import { connect } from "react-redux"; 

const GuestGame = ({ game, games, gameNme, login, openPopup, siteImages}) => {
  const [guestGame, setGuestGame] = useState({});
    
  useEffect(() => {
      async function fetch(){
          let data=await loadJson();
          setGuestGame(data);
      }
          fetch().catch(err=>console.log(err))
    }, []);

  let gameLink = "";
  const otherGames = {
    ...games,
  };

  delete otherGames.for_you;
  delete otherGames[gameNme];

  const loadGame = (e) => {
    window.location.replace(e.target.getAttribute("data-target"));
    // alert(e.target.getAttribute("data-target"));
    // alert(gameLink);
  };
  return (
    <div className="row">
      {game &&
        Object.keys(game).map((gmeky, key) => {
          if (login) {
            // compared gamekeys with the keys of guestgames json
            switch (gameNme) {
              case "baccarat":
                gameLink = "/livebaccarat/#" + gmeky;
                break;
              case "roulette":
                gameLink = "/liveroulette/#" + gmeky;
                // alert(gameLink);
                // gameLink = "https://ux.itone.biz/site/V2.0/roulette.html";
                break;
              case "andar_bahar":
                gameLink = "/liveandar/#" + gmeky;
                break;
              case "dragon_tiger":
                gameLink = "/livedragontiger/#" + gmeky;
                break;
              case "lucky_7":
                gameLink = "/livelucky7/#" + gmeky;
                break;
              default:
                break;
            }
          }
          return (
            <Fragment>
              {/* {gameLink} */}
              {/* {key} */}
              {/* {gameNme === "for_you" && key === 12 && ( */}
              {gameNme === "for_you" && key === 2 && (
                <div className="col-12 mx-0 px-1 my-4">
              {
                Object.keys(siteImages.images).length > 0 &&
                  <img
                    src={IMGBUCKETURL + siteImages?.images?.lobby_foryou_separator[0]}
                    className="img-fluid mx-auto d-block"
                    alt=""
                  ></img>
                }
                  {/* <img
                    src={IMAGE_BUCKET_URL+guestGame.welcome_bonus_banner}
                    className="img-fluid mx-auto d-block"
                    alt=""
                  /> */}
                </div>
              )}
              <div
                style={{ position: "relative" }}
                className="col-lg-2 col-md-4 col-sm-4 col-6 mx-0 px-1 mb-2"
              >
                {/* <div class="content-overlay">
                  <p style={{ textAlign: "center", marginTop: "10%" }}>PLAY</p>
                </div> */}
                {/* <Link to={gameLink}> */}
                <div className="row m-0 p-1">
                  <div className="col-12 ms-0 p-0">
                    <div class="content-overlay text-center pt-5 ">
                      {/* {gameLink} */}
                      <a
                        data-target={gameLink}
                        class="hoverButton "
                        onClick={(e) => openPopup("login")}
                      >
                        PLAY
                      </a>
                    </div>
                    <img
                      onClick={(e) => openPopup("login")}
                      src={IMAGE_BUCKET_URL+game[gmeky].image}
                      className="img-fluid mx-auto d-block shineImg"
                    />
                  </div>
                </div>
                {/* </Link> */}
              </div>
            </Fragment>
          );
        })}
{/* sepatator */}
      {!login && gameNme !== "for_you" && (
        <Fragment>
          <div className="col-12 mx-0 px-1 my-4">
          {
                Object.keys(siteImages.images).length > 0 &&
            <img
              src={IMGBUCKETURL + siteImages?.images?.lobby_foryou_separator[0]}
              className="img-fluid mx-auto d-block"
              alt=""
            ></img>
          }
            {/* <img
              src={IMAGE_BUCKET_URL+guestGame.welcome_bonus_banner}
              className="img-fluid mx-auto d-block"
              alt=""
            /> */}
          </div>
          {otherGames &&
            Object.keys(otherGames).map((gmeky, key) => {
              return (
                <Fragment key={key}>
                  {Object.keys(otherGames[gmeky].games).map((childKy, ky) => {
                    return (
                      <div
                        key={ky}
                        className="col-lg-2 col-md-4 col-sm-4 col-6 mx-0 px-1 mb-2"
                      >
                        <Link to={gameLink}>
                          <div className="row m-0 p-1">
                            <div className="col-12 ms-0 p-0">
                              <img
                                src={IMAGE_BUCKET_URL+otherGames[gmeky].games[childKy].image}
                                className="img-fluid mx-auto d-block shineImg"
                              />
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </Fragment>
              );
            })}
        </Fragment>
      )}
    </div>
  );
};

const BindStateToProps = (state=>{
  return {
      siteImages : state.siteImages
  }
})
export default connect(BindStateToProps)(GuestGame);

// export default GuestGame;
