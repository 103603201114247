import TranslatesContent from "../../DataStore/Languages/translatesContent";
import React, { useEffect, useState } from 'react';
const WhatsappButton = () => {
  return (
    // <div className="col-12 text-center mt-4 mt-lg-4 mt-md-4 mt-sm-4 mt-xl-4">
    //   <div class="row">
    //     <div class="col-12 col-xl-5 col-lg-4 col-md-4 col-sm-12 offset-lg-2 offset-xl-4 offset-0">
    //       <div class="row">
    //         <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
    //           <div class="tobIncontact mt-0 mt-3 mt-lg-3 mt-md-3 mt-sm-3 mt-xl-3 mb-2">
    //             <TranslatesContent contentKey="24-7-support" />
    //           </div>
    //         </div>
    //         <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
    //           <div class="newcashierButton">
    //             <i
    //               class="fa-brands fa-whatsapp"
    //               style={{ fontSize: "25px" }}
    //             ></i>
    //             <a href="https://wa.me/+94765992992" target="_blank">
    //               +94 765 992 992
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    // <div class="row">
    //   <div class="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-8 offset-xl-3 offset-lg-3 offset-md-2 offset-sm-2 offset-0 newcashierContactBox">
    //     <div class="row">
    //       <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
    //         <div class="newcashierButton">
    //           <i class="fa-brands fa-whatsapp" style={{ fontsize: "25px" }}></i>
    //           <a href="https://wa.me/+94765992992" target="_blank">
    //             +94 77 000000{" "}
    //           </a>
    //         </div>
    //       </div>
    //       <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
    //         <div class="tobIncontact mt-0 mt-3 mt-lg-3 mt-md-3 mt-sm-3 mt-xl-3 mb-2">
    //           {" "}
    //           24X7 Help Line
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="row">
      <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-8 offset-xl-3 offset-lg-3 offset-md-2 offset-sm-2 offset-0 newcashierContactBox">
        <div className="row">
          <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="newcashierButton">
            <a href="https://wa.me/+94771482222" target="_blank">
              <i className="fa-brands fa-whatsapp" style={{ fontsize: "25px" }}></i>{" "}
              <TranslatesContent contentKey="whatsapp-number-3" />{" "}
              </a>
            </div>
          </div>
          <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
            <div className="tobIncontact mt-0 mt-3 mt-lg-2 mt-md-2 mt-sm-2 mt-xl-2 mb-2">
              {" "}
              24X7 Help Line
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsappButton;
